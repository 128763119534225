import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesAppService {

  public mostrarAceiteCookies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
  ) { }

  public updateMostrarAceiteCookies(status: boolean) {
    this.mostrarAceiteCookies$.next(status);
  }

  public getCurrentMostrarAceiteCookies(): boolean {
    return this.mostrarAceiteCookies$.getValue();
  }

  public onMostrarAceiteCookiesChange(): Observable<boolean> {
    return this.mostrarAceiteCookies$.asObservable();
  }

}
